export default {
    name: 'location-hours',
    components: {},
    props: {
        defaultTime: Object,
        hours: Object,
        change: Function,
        dayClose: {type: Boolean, default: false},
        limitTime: {type: Boolean, default: false}
    },
    data() {
        return {
            hoursCopy: null,
            Hours: null,
            hoursTemplate: [
                {value: 0, text: '12:00 A.M'},
                {value: 1, text: '12:30 A.M'},
                {value: 2, text: '1:00 A.M'},
                {value: 3, text: '1:30 A.M'},
                {value: 4, text: '2:00 A.M'},
                {value: 5, text: '2:30 A.M'},
                {value: 6, text: '3:00 A.M'},
                {value: 7, text: '3:30 A.M'},
                {value: 8, text: '4:00 A.M'},
                {value: 9, text: '4:30 A.M'},
                {value: 10, text: '5:00 A.M'},
                {value: 11, text: '5:30 A.M'},
                {value: 12, text: '6:00 A.M'},
                {value: 13, text: '6:30 A.M'},
                {value: 14, text: '7:00 A.M'},
                {value: 15, text: '7:30 A.M'},
                {value: 16, text: '8:00 A.M'},
                {value: 17, text: '8:30 A.M'},
                {value: 18, text: '9:00 A.M'},
                {value: 19, text: '9:30 A.M'},
                {value: 20, text: '10:00 A.M'},
                {value: 21, text: '10:30 A.M'},
                {value: 22, text: '11:00 A.M'},
                {value: 23, text: '11:30 A.M'},
                {value: 24, text: '12:00 P.M'},
                {value: 25, text: '12:30 P.M'},
                {value: 26, text: '1:00 P.M'},
                {value: 27, text: '1:30 P.M'},
                {value: 28, text: '2:00 P.M'},
                {value: 29, text: '2:30 P.M'},
                {value: 30, text: '3:00 P.M'},
                {value: 31, text: '3:30 P.M'},
                {value: 32, text: '4:00 P.M'},
                {value: 33, text: '4:30 P.M'},
                {value: 34, text: '5:00 P.M'},
                {value: 35, text: '5:30 P.M'},
                {value: 36, text: '6:00 P.M'},
                {value: 37, text: '6:30 P.M'},
                {value: 38, text: '7:00 P.M'},
                {value: 39, text: '7:30 P.M'},
                {value: 40, text: '8:00 P.M'},
                {value: 41, text: '8:30 P.M'},
                {value: 42, text: '9:00 P.M'},
                {value: 43, text: '9:30 P.M'},
                {value: 44, text: '10:00 P.M'},
                {value: 45, text: '10:30 P.M'},
                {value: 46, text: '11:00 P.M'},
                {value: 47, text: '11:30 P.M'},
            ]
        }
    },
    computed: {},
    mounted() {
        this.Hours = this.hours;
        this.hoursCopy = JSON.parse(JSON.stringify(this.hours));
    },
    methods: {
        onUpdate() {
            if (this.change) this.change(this.Hours);
        },

        companyHours(day) {
            if (this.limitTime) {
                return this.hoursTemplate.filter(el => {
                    return day.Start <= el.value && day.End >= el.value;
                })
            }

            return this.hoursTemplate;
        }
    }
}
